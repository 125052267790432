<template>
  <div class="certificate-wrap">
    <div class="filters" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="keyword"
              :placeholder="$t('teacher.please_enter_a_certificate_name')"
              style="width: 240px"
              @search="onSearch"
            />
            <!-- 请输入证书名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <template v-if="dataList.length">
      <div class="list">
        <div class="item fl" v-for="(item, index) in dataList" :key="index">
          <span
            class="status"
            v-if="item.endTime == 0 || currentTime < item.endTime"
            >{{ $t("Pub_Normal") }}</span
          >
          <!-- 正常 -->
          <span class="status red" v-else>{{ $t("CM_Expired") }}</span>
          <!-- 已过期 -->
          <a-image
            v-if="item.honorId"
            class="picture"
            :width="364"
            :height="216"
            :src="item.picture"
          />
          <img
            v-else
            class="picture"
            :src="item.picture"
            @click="$refs.CertificatePreviewRef.initData(item)"
          />
          <div class="title">{{ item.honorName }}</div>
          <div class="info">
            <!-- 荣誉时间： -->
            {{ $t("teacher.honor_time") }}：{{
              dateFormat(item.startTime, "YYYY-MM-DD")
            }}
            ~
            {{ dateFormat(item.endTime, "YYYY-MM-DD") }}
          </div>
        </div>
      </div>
      <div class="page-Wrap">
        <a-pagination
          show-quick-jumper
          hideOnSinglePage
          :defaultPageSize="12"
          v-model:current="currentPage"
          :total="pageTotal"
          @change="pageChange"
        >
          <template #itemRender="{ type, originalElement }">
            <a-button v-if="type === 'prev'">{{ $t("pre") }}</a-button>
            <a-button v-else-if="type === 'next'">{{ $t("next") }}</a-button>
            <renderVNode v-else :vnode="originalElement"></renderVNode>
          </template>
        </a-pagination>
      </div>
    </template>
    <a-empty v-else style="padding: 60px 0" />
  </div>
  <CertificatePreview ref="CertificatePreviewRef" source="lecturerZone" />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs } from "vue";
import { dateFormat } from "@/utils/tools";
import { teacherHonor } from "@/api/teacher";
import ListFilter from "@/components/filter/ListFilter.vue";
import CertificatePreview from "@/components/new/CertificatePreview.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
    CertificatePreview,
  },
  setup() {
    const { t: $t } = useI18n();
    const state = reactive({
      reviewStatus: [
        { id: 1, name: $t("CM_Expired") }, // 已过期
        { id: 2, name: $t("CM_UnExpired") }, // 未过期
      ],
      dataList: [],
      loading: true,
      currentPage: 1,
      pageTotal: 1,
      keyword: "",
      status: 0,
    });

    const currentTime = Date.parse(new Date()) / 1000;

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      state.loading = true;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      state.loading = true;
      getList(true);
    };

    const getList = (scroll = false) => {
      teacherHonor({
        page: state.currentPage,
        pageSize: 12,
        keyword: state.keyword,
        timeStatus: state.status,
      }).then((res) => {
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        state.loading = false;
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = (value) => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      dateFormat,
      ...toRefs(state),
      currentTime,
      switchStatus,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
.certificate-wrap {
  .filters {
    margin-bottom: 10px;
    .search {
      .mixinFlex(flex-end);
    }
    .filter {
      border-bottom: none;
      padding-top: 0;
      &:last-child {
        padding-bottom: 14px;
      }
    }
  }
  .list {
    display: inline-block;
    .item {
      position: relative;
      width: 364px;
      height: 292px;
      text-align: center;
      margin-right: 24px;
      margin-bottom: 22px;
      .status {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 52px;
        height: 24px;
        line-height: 24px;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        z-index: 1;
        cursor: pointer;
        &.red {
          background: rgba(210, 81, 81, 0.5);
        }
      }
      ::v-deep(.picture) {
        width: 364px;
        height: 216px;
        object-fit: cover;
        border-radius: 4px;
      }
      .title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-top: 12px;
        .mixinEllipsis(24px);
      }
      .info {
        margin-top: 8px;
        color: #666666;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        em {
          margin: 0 10px;
          color: #d8d8d8;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.page-Wrap {
  .page-a {
    padding: 0 4px;
    height: 32px;
    display: inline-block;
  }
  ::v-deep(.ant-pagination-item) {
    border: none;
  }
  ::v-deep(.ant-pagination-item-active) {
    background-color: @color-theme;
    a {
      color: #fff !important;
    }
  }
}
</style>
